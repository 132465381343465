import { Form, FormProps } from "components/form";

const AuthForm = (props: FormProps) => {
  return (
    <Form
      {...props}
      method="post"
      overrideStyles="bg-dynamo bg-dark mw-100"
      style={{ width: "25rem" }}
    />
  );
};

export default AuthForm;
