import { type ElementColor } from "types/element";
import clsx from "clsx";
import Input from "./input";

export interface Props {
  label?: string;
  type?: string;
  name: string;
  value?: any;
  required?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholderText?: string;
  helperText?: string;
  className?: string;
  overrideStyles?: string;
  overrideLabelStyles?: string;
  inputStyles?: string;
  helperTextStyles?: string;
  labelTextColor?: ElementColor;
  helperTextColor?: ElementColor;
  maxLength?: number;
  customInputComponent?: React.ReactNode;
}

const InputWithLabel = ({
  label,
  type = "text",
  name,
  value,
  required,
  disabled,
  onChange,
  placeholderText = "",
  helperText,
  className,
  overrideStyles,
  overrideLabelStyles,
  inputStyles,
  helperTextStyles,
  labelTextColor = "dark",
  helperTextColor,
  maxLength,
  customInputComponent,
}: Props) => {
  const defaultStyles = "mb-4";
  const classNames = clsx(overrideStyles ? overrideStyles : defaultStyles, className);

  const defaultLabelStyles = "inline-block, mb-1";
  const labelClassNames = clsx(
    `text-${labelTextColor}`,
    overrideLabelStyles ? overrideLabelStyles : defaultLabelStyles
  );

  const inputClassNames = clsx("form-control", inputStyles);
  const helperClassNames = clsx(
    "form-text",
    helperTextStyles,
    helperTextColor ? `text-${helperTextColor}` : ""
  );

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled && onChange && typeof onChange === "function") onChange(event);
  };

  return (
    <div className={classNames}>
      {label && (
        <label htmlFor={name} className={labelClassNames}>
          {label}
        </label>
      )}
      {customInputComponent ? (
        customInputComponent
      ) : (
        <Input
          type={type}
          id={name}
          name={name}
          value={value}
          className={inputClassNames}
          placeholder={placeholderText}
          required={required}
          disabled={disabled}
          onChange={handleOnChange}
          maxLength={maxLength}
        />
      )}
      {helperText && <div className={helperClassNames}>{helperText}</div>}
    </div>
  );
};

export default InputWithLabel;
