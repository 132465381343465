import { useState, useEffect } from "react";

import Page from "@/layout/page";
import Heading from "components/typography/heading";
import AuthForm from "components/auth/form";
import AuthInput from "components/auth/input";
import BlockButton from "components/buttons/block-button";
import Alert from "components/common/alert";
import useUser from "hooks/use-user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Fetcher from "lib/fetcher";
import { useRouter } from "next/router";

export default function Home() {
  const router = useRouter();
  const [loggingIn, setLoggingIn] = useState(false);

  const {} = useUser({
    redirectTo: "/account",
    redirectIfFound: true,
  });

  const [email, setEmail] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const fetcher = new Fetcher();
    setLoggingIn(true);

    fetcher
      .post("/api/auth/magiclogin", {
        arg: {
          email,
        },
      })
      .then((data) => {
        router.push({ pathname: "/auth/magiclogin", query: { email } });
      })
      .catch((error) => {
        console.log("error", error);
        setShowAlert(true);
        setLoggingIn(false);
      });
  };

  const handelLoginDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <Page titleOverride="Dynamo Cover Portal | Login To Your Account">
      <div className="d-flex flex-column mx-auto">
        <div className="mx-lg-auto col-lg-8 text-center mb-4">
          <Heading className="text-dynamo">
            Please login to view, download and manage your policies online.
          </Heading>
          <Alert
            className="alert-danger"
            message="
            Enter your email address below and we will send you a secure link to login. Please note
            you must use the same device and network to access your account also make sure that your browser is not in incognito/private mode.
            "
          />
        </div>

        <div className="mx-lg-auto">
          <AuthForm onSubmit={handleSubmit}>
            <AuthInput
              label="Email address"
              placeholderText="email@example.com"
              type="email"
              name="username"
              required={true}
              onChange={handelLoginDataChange}
            />
            <BlockButton type="submit" color="success" showSpinner={loggingIn} disabled={loggingIn}>
              Login with email
            </BlockButton>
            {showAlert && (
              <Alert
                message="Something went wrong, please try again or contact us if the problem persists"
                className="alert-danger mt-2"
              />
            )}
            <Alert
              className="alert alert-secondary mt-4 mb-0"
              message="We’ll email you a magic link for a
            password-free sign in."
              icon={<FontAwesomeIcon className="me-2" icon={faCircleInfo} size="xs" />}
            />
          </AuthForm>
        </div>
      </div>
    </Page>
  );
}
