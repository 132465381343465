import clsx from "clsx";

export interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  action?: string;
  method?: string;
  overrideStyles?: string;
  className?: string;
  children: React.ReactNode;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  style?: React.CSSProperties;
}

const Form = ({ action, method, overrideStyles, className, children, onSubmit, style }: Props) => {
  const defaultStyles = "bg-light";
  const classNames = clsx(
    "rounded-2 p-4 mx-auto",
    className,
    overrideStyles ? overrideStyles : defaultStyles
  );

  return (
    <form action={action} method={method} onSubmit={onSubmit} className={classNames} style={style}>
      {children}
    </form>
  );
};

export default Form;
