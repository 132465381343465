import Head from "next/head";

type Props = {
  title?: string;
  titleOverride?: string;
  children: React.ReactNode;
};

const Page = ({ title, titleOverride, children }: Props) => {
  return (
    <>
      <Head>
        <title>
          {titleOverride ? titleOverride : title ? `${title} - Dynamo Cover` : "Dynamo Cover"}
        </title>
        <meta name="description" content="Insurance Products for all your needs" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className="px-sm-4 container mx-auto py-4 px-3">{children}</main>
    </>
  );
};

export default Page;
