import Button, { Props as ButtonProps } from "./button";

const BlockButton = (props: ButtonProps) => {
  return (
    <div className="d-grid">
      <Button {...props} />
    </div>
  );
};

export default BlockButton;
