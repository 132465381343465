import clsx from "clsx";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  disabled?: boolean;
  styles?: string;
  label?: React.ReactNode;
  labelStyles?: string;
}

const Radio = ({ id, name, styles, label, labelStyles, ...props }: Props) => {
  const classNames = clsx("form-check", styles);
  const labelClassNames = clsx("form-check-label", labelStyles);

  return (
    <div className={classNames}>
      <input type="radio" id={id || name} name={name} className="form-check-input" {...props} />
      {label && (
        <label className={labelClassNames} htmlFor={id || name}>
          <strong>{label}</strong>
        </label>
      )}
    </div>
  );
};

export default Radio;
