import { InputWithLabel, InputWithLabelProps } from "components/form";
import DatePicker from "react-date-picker/dist/entry.nostyle";
import clsx from "clsx";
import { DateTime } from "luxon";

interface Props extends Omit<InputWithLabelProps, "onChange"> {
  onChange?: (date: Date) => void;
}

const DateInput = ({ name, inputStyles, value, onChange, required, disabled, ...props }: Props) => {
  const inputClassNames = clsx("form-control", inputStyles);

  return (
    <InputWithLabel
      name={name}
      {...props}
      customInputComponent={
        <DatePicker
          name={name}
          className={inputClassNames}
          format="dd/MM/y"
          value={value ? DateTime.fromISO(value).toJSDate() : value}
          onChange={onChange}
          clearIcon={null}
          required={required}
          disabled={disabled}
        />
      }
    />
  );
};

export default DateInput;
