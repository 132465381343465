import clsx from "clsx";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  name: string;
  value?: any;
  required?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholderText?: string;
  styles?: string;
  maxLength?: number;
  minLength?: number;
}

const Input = ({
  type = "text",
  name,
  disabled,
  onChange,
  placeholderText,
  styles,
  ...props
}: Props) => {
  const classNames = clsx("form-control", styles);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled && onChange && typeof onChange === "function") onChange(event);
  };

  return (
    <input
      type={type}
      id={name}
      name={name}
      disabled={disabled}
      className={classNames}
      placeholder={placeholderText}
      onChange={handleOnChange}
      {...props}
    />
  );
};

export default Input;
