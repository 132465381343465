type Props = {
  children: React.ReactNode;
  onClick?: () => void;
};

const InputGroup = ({ children, onClick }: Props) => {
  return (
    <span className="input-group-text" onClick={onClick}>
      {children}
    </span>
  );
};

export default InputGroup;
