import { type ElementColor } from "types/element";
import clsx from "clsx";

export interface Props {
  text?: string;
  textStyles?: string;
  textColor?: ElementColor;
}

const FormText = ({ text, textStyles, textColor }: Props) => {
  const classNames = clsx("form-text", textStyles, textColor ? `text-${textColor}` : "");

  return <div className={classNames}>{text}</div>;
};

export default FormText;
