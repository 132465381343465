import "react-phone-number-input/style.css";
import { InputWithLabel, InputWithLabelProps } from "components/form";
import PhoneNumberInput from "react-phone-number-input";
import clsx from "clsx";

interface Props extends Omit<InputWithLabelProps, "onChange"> {
  onChange: (string: string) => void;
}

const PhoneInput = ({
  type,
  name,
  inputStyles,
  value,
  onChange,
  required,
  disabled,
  ...props
}: Props) => {
  const inputClassNames = clsx("form-control", inputStyles);

  return (
    <InputWithLabel
      name={name}
      {...props}
      customInputComponent={
        <PhoneNumberInput
          international
          defaultCountry="GB"
          countryCallingCodeEditable={false}
          type={type}
          id={name}
          name={name}
          value={value}
          className={inputClassNames}
          onChange={onChange}
          required={required}
          maxLength={15}
          disabled={disabled}
        />
      }
    />
  );
};

export default PhoneInput;
