import React from "react";
import clsx from "clsx";

type Props = {
  message: React.ReactNode;
  className: string;
  icon?: React.ReactNode;
  onDismiss?: () => void;
};

function Alert({ message = "Success!", className, icon, onDismiss }: Props) {
  const classNames = clsx(
    className,
    "alert",
    onDismiss ? "alert-dismissible" : "",
    icon ? "d-flex align-items-start" : ""
  );

  return (
    <div className={classNames} role="alert">
      {icon ? <div>{icon}</div> : null}
      <div>
        {message}
        {onDismiss && <button type="button" className="btn-close" onClick={onDismiss}></button>}
      </div>
    </div>
  );
}

export default Alert;
